body {
    overflow-y: scroll;
}

.program-box {
    padding: 3.5%;
    margin-bottom: 2%;
    background-color: #f7f7f7;
}

.application-description {
    font-family: 'Source Sans Pro';
    font-size: 16px;
}

.program-body {
    color: #404040;
    font-family: 'Source Sans Pro';
    font-size: 16px;
    font-weight: 300;
}

#okta-sign-in .terms {
    font-size: 15px;
    font-family: 'Source Sans Pro';
    color: #717171;
}

#okta-sign-in .desc {
    font-size: 13px;
    text-align: center;
}

a, .indi-button--tertiary {
    color: #006bef;
}

.indi-form__input--disabled {
    color: #717171;
}


#okta-sign-in button {
    min-width: 85px;
}

.footer-text {
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: normal;
    padding-right: 2%;
    text-align: right;
    color: #404040;
}

.program-body .program {
    font-weight: 400;
}

#mfa-fieldset .title {
    text-align: center
}

#mfa-fieldset label {
    font-size: 14px;
}

#mfa-fieldset .send-code {
    padding: 8px 16px 8px 16px;
    font-size: 14px;
}

#settings div {
    position: relative;
    height: 0px;
    overflow: hidden;
    max-width: 100%;
    padding-bottom: 140%;
}

#main {
    margin: auto;
    max-width: 1500px;
}

#settings iframe {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%
}

#okta-sign-in {
    line-height: 1.4;
    background-color: #f9f9f9;
    color: #5c6971;
    position: relative;
    overflow: auto;
    border-radius: 3px;
    border-style: solid;
    border-width: 1px;
    height: auto;
    margin: 2px auto 8px;
    width: 500px;
    min-width: 300px
}

    #okta-sign-in label,
    #okta-sign-in a,
    #okta-sign-in p,
    #okta-sign-in input {
        font-size: 16px;
    }

    #okta-sign-in .indi-notification {
        font-size: 12px;
    }

@media only screen and (max-device-width:480px) {
    #okta-sign-in {
        margin-top: 0;
        width: 100%
    }
}

@media only screen and (max-width:400px) {
    #okta-sign-in {
        width: 100%
    }
}

@media only screen and (max-height:750px) {
    #okta-sign-in {
        margin-top: 0
    }
}

#okta-sign-in.no-beacon .auth-content {
    padding: 5px;
}

#okta-sign-in.no-beacon .auth-header {
    padding-bottom: 30px
}

.auth .content {
    min-height: 100%;
    min-width: 300px;
    display: inline-block;
    width: 100%
}

    .auth .content:after {
        content: "";
        display: block;
        height: 30px
    }

#okta-sign-in.auth-container.main-container {
    color: #5c6971
}

#okta-sign-in.auth-container .okta-sign-in-header {
    color: #2f3f4a
}

#okta-sign-in.auth-container.main-container {
    background-color: #fff;
    border-color: #ddd #ddd #d8d8d8;
    box-shadow: 0 2px 0 hsla(0, 0%, 68.6%, .12)
}

@media only screen and (min-device-width:320px) and (max-device-width:480px) and (-webkit-min-device-pixel-ratio:2) {
    #okta-sign-in.auth-container.main-container {
        border-width: 0;
        box-shadow: none
    }
}

@media only screen and (min-device-width:320px) and (max-device-width:568px) and (-webkit-min-device-pixel-ratio:2) {
    #okta-sign-in.auth-container.main-container {
        border-width: 0;
        box-shadow: none
    }
}

@media only screen and (min-device-width:375px) and (max-device-width:667px) and (-webkit-min-device-pixel-ratio:2) {
    #okta-sign-in.auth-container.main-container {
        border-width: 0;
        box-shadow: none
    }
}

@media only screen and (min-device-width:414px) and (max-device-width:736px) and (-webkit-min-device-pixel-ratio:3) {
    #okta-sign-in.auth-container.main-container {
        border-width: 0;
        box-shadow: none
    }
}

@media screen and (device-width:320px) and (device-height:640px) and (-webkit-device-pixel-ratio:2) {
    #okta-sign-in.auth-container.main-container {
        border-width: 0;
        box-shadow: none
    }
}

@media screen and (device-width:360px) and (device-height:640px) and (-webkit-device-pixel-ratio:3) {
    #okta-sign-in.auth-container.main-container {
        border-width: 0;
        box-shadow: none
    }
}

#okta-sign-in .auth-header {
    padding: 30px 140px 75px;
    position: relative;
    border-bottom: 1px solid #ddd;
    z-index: 10;
    -moz-transition: padding-bottom .4s;
    -webkit-transition: padding-bottom .4s;
    transition: padding-bottom .4s
}

#okta-sign-in div,
#okta-sign-in var {
    margin: 0;
    outline: 0;
    font-family: 'Source Sans Pro';
    vertical-align: baseline;
}


#who-am-I .card {
    border: none;
    margin-top: 0.5%;
}

    #who-am-I .card .card-header {
        border: none;
        background-color: #f7f7f7;
    }

    #who-am-I .card .card-body {
        background-color: #f7f7f7;
        padding-left: 2%;
        padding-right: 6%;
    }

        #who-am-I .card .card-body .description {
            color: #404040;
        }

#who-am-I .card-header .title {
    color: #404040;
    font-size: 24px;
}

.card-body{
    padding: 0 1rem;
}

.indi-doc-header__banner {
    position: initial;
}

.single-event-entry {
    max-width: 235px;
    font-size: 18px;
    line-height: 21px;
    padding: 20px 10px;
    border-bottom: 1px solid #000;
}

    .single-event-entry > .row {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        flex-wrap: wrap;
    }

        .single-event-entry > .row > [class*='col-'] {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            flex-direction: column;
            min-height: 47px;
        }

            .single-event-entry > .row > [class*='col-'] > a {
                position: relative;
                top: 50%;
                -webkit-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                transform: translateY(-50%);
            }

.tri-section .single-event-entry:last-child {
    border-bottom: 0;
    padding-bottom: 0;
}

.event-single-entry-date {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.event-single-entry-dm {
    font-size: 32px;
    color: #404040;
    line-height: 21px;
}

.event-single-entry-y {
    color: #404040;
    font-weight: 600;
    margin-top: 5px;
}

.single-event-related-to {
    color: #4a4a4a;
    font-size: 17px;
}

.single-event-entry-title {
    font-weight: 600;
}

a.full-cal-link {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    display: inline-block;
    margin-top: 15px;
}

.single-event-post {
    border: 1px solid #616161;
    padding: 25px;
    margin: 30px 0 0;
}

.first-single-event-post {
    border: 3px solid #ffab00;
}

a:hover, a:focus, a:visited {
    text-decoration: none;
}

a:focus,
button:focus,
input[type="button"]:focus,
.indi-button:focus {
    outline: 5px auto rgba(0, 150, 255, 1) !important;
    -webkit-outline: 5px auto rgba(0, 150, 255, 1) !important;
    -moz-outline: 5px auto rgba(0, 150, 255, 1) !important;
    -ms-outline: 5px auto rgba(0, 150, 255, 1) !important;
    -o-outline: 5px auto rgba(0, 150, 255, 1) !important;
    /* Use a border to apply the outline */
    border: 5px auto rgba(0, 150, 255, 1) !important;
}
.print:focus {
    outline: 0 !important;
}

.accordion-header > button {
    background-color:transparent;
    border:none;
}

.indi-long-form-text a {
    color: #006AEF;
    fill: #006AEF;
}

    .indi-long-form-text a:hover,
    .indi-long-form-text a:focus {
        color: #004AD4;
        fill: #004AD4;
    }
